import { Header, NationwideBanner } from '@hc-frontend/shells-rx-cards-ui';
import DiscountCardForm from '@hc-frontend/shells-rx-cards-ui/organisms/discount-card-form/rx-cards-lp/variant-a';
import LandingMainSection, {
  LandingMainSectionSubtitle,
  LandingMainSectionTitle,
} from '@hc-frontend/shells-rx-cards-ui/organisms/landing-main-section/rx-cards-lp/variant-a';
import LandingInputLayout from '@hc-frontend/shells-rx-cards-ui/templates/landing-input-layout/rx-cards-lp/variant-a';

export default function LandingPage() {
  return (
    <LandingInputLayout
      title={<LandingMainSectionTitle />}
      subTitle={<LandingMainSectionSubtitle />}
      list={<LandingMainSection />}
      rightSide={<DiscountCardForm />}
      banner={<NationwideBanner />}
      header={<Header />}
    />
  );
}
