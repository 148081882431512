import Box from '@mui/material/Box';
import type { ThemeOptions } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'next-i18next';

export function LandingMainSectionTitle() {
  const { t } = useTranslation('landing');

  return (
    <Typography
      component="h1"
      variant="titleSM"
      pr={{ lg: '20%' }}
      sx={(theme) => ({
        typography: {
          ...theme.typography.titleSM,
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.primary,
        } as Partial<ThemeOptions['typography']>,
        lineHeight: {
          xs: theme.typography.pxToRem(42),
          md: 'normal',
        },
        px: {
          xs: 1,
          md: 0,
        },
      })}
    >
      {t('getYourDiscountCard')}
    </Typography>
  );
}

export function LandingMainSectionSubtitle() {
  const { t } = useTranslation('landing');
  return (
    <Typography
      component="h2"
      width={{ xs: '100%', lg: '37vw' }}
      minHeight={(theme) => ({ lg: theme.typography.pxToRem(106) })}
      mt={2}
      pr={{ md: '5%' }}
      variant="headingTwo"
      sx={(theme) => ({
        typography: {
          ...theme.typography.headingTwo,
          color: theme.palette.primary,
          [theme.breakpoints.down('lg')]: {
            fontSize: theme.typography.pxToRem(22),
          },
        } as Partial<ThemeOptions['typography']>,
        lineHeight: {
          xs: theme.typography.pxToRem(30),
          md: 'normal',
        },
        width: {
          xs: theme.typography.pxToRem(267),
          sm: '100%',
          md: '100%',
        },
      })}
    >
      {t('savingOnAllYourPrescription')}
    </Typography>
  );
}

function LandingMainSectionList({ children }: React.PropsWithChildren) {
  return (
    <Box width="100%" pr={{ xs: 0, lg: '7%' }} color="primary">
      <ul
        style={{
          paddingLeft: '1.3rem',
        }}
      >
        {children}
      </ul>
    </Box>
  );
}

function LandingMainSectionListItem({ children }: React.PropsWithChildren) {
  return (
    <Typography
      component="li"
      variant="paragraphLG"
      fontWeight="600"
      sx={(theme) => ({
        typography: {
          color: theme.palette.primary,
          [theme.breakpoints.down('lg')]: {
            fontSize: theme.typography.pxToRem(18),
          },
        } as Partial<ThemeOptions['typography']>,
      })}
    >
      {children}
    </Typography>
  );
}

export function LandingMainSection() {
  const { t } = useTranslation('landing');

  return (
    <Markdown
      options={{
        disableParsingRawHTML: true,
        wrapper: 'article',
        overrides: {
          ul: {
            component: LandingMainSectionList,
          },
          li: {
            component: LandingMainSectionListItem,
          },
        },
      }}
    >
      {t('mainSection')}
    </Markdown>
  );
}

export default LandingMainSection;
