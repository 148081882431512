import { PhoneInputFormat } from '@hc-frontend/core-ui-components';
import { buildPhoneNumber } from '@hc-frontend/shells-rx-cards-business';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { forwardRef, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { useValidateEntity } from '../../hooks';
import type { PhoneInputProps } from './phone-input.types';

export function PhoneInput<T extends Record<string, string>>({
  fieldName,
  formControl,
  textInputProps,
}: PhoneInputProps<T>) {
  const validate = useValidateEntity(buildPhoneNumber);

  const TextFieldWrapper = useMemo(
    () =>
      // eslint-disable-next-line react/no-unstable-nested-components
      forwardRef<HTMLDivElement, React.ComponentProps<typeof TextField>>(
        (props, ref) => (
          <TextField
            {...textInputProps}
            {...props}
            ref={ref}
            title={fieldName}
            inputProps={{
              'aria-label': fieldName,
            }}
            data-testid={`${fieldName}-input`}
          />
        ),
      ),
    [textInputProps, fieldName],
  );

  return (
    <Controller<T>
      rules={{
        validate,
      }}
      name={fieldName}
      control={formControl}
      render={({ field, fieldState }) => (
        <FormControl fullWidth error={fieldState.invalid}>
          <PhoneInputFormat
            {...field}
            id="phone"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            customInput={TextFieldWrapper}
            placeholder="(XXX) XXX-XXXX"
            error={fieldState.invalid}
          />
          <FormHelperText error>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
