import {
  ComponentLoader,
  configureLazyComponent,
  Experiment,
  LandingFaqLayout,
  LandingFaqSection,
} from '@hc-frontend/shells-rx-cards-ui';
import type { GetServerSideProps } from 'next';

import LandingControlVariant from '../experiments/rx-cards-lp/landing-page-variant-a';
import { LayoutWithoutHeader } from '../layouts';
import {
  createExperimentProps,
  createRaygunContext,
  createTranslationProps,
} from '../props';

export const getServerSideProps: GetServerSideProps = async (context) => {
  await createRaygunContext(context);

  const translationProps = await createTranslationProps(context, [
    'footer',
    'landing',
    'seo',
  ]);

  const experimentProps = await createExperimentProps(['RX_CARDS_LP'], context);

  return {
    props: {
      ...translationProps,
      ...experimentProps,
    },
  };
};

const VariantA = configureLazyComponent(
  import('../experiments/rx-cards-lp/landing-page-variant-a'),
);

const VariantB = configureLazyComponent(
  import('../experiments/rx-cards-lp/landing-page-variant-b'),
);

export function LandingPageExperimentVariantA() {
  return (
    <ComponentLoader fallbackComponent={<LandingControlVariant />}>
      <VariantA />
    </ComponentLoader>
  );
}

export function LandingPageExperimentVariantB() {
  return (
    <ComponentLoader fallbackComponent={<LandingControlVariant />}>
      <VariantB />
    </ComponentLoader>
  );
}

function LandingPageExperiment() {
  return (
    <Experiment
      control={<LandingControlVariant />}
      experimentName={'RX_CARDS_LP'}
      variants={{
        0: <LandingPageExperimentVariantA />,
        1: <LandingPageExperimentVariantB />,
      }}
    />
  );
}

export function Index() {
  return (
    <>
      <LandingPageExperiment />
      <LandingFaqLayout>
        <LandingFaqSection />
      </LandingFaqLayout>
    </>
  );
}

Index.title = 'landingTitle';
Index.description = 'landingDescription';
Index.layout = LayoutWithoutHeader;

export default Index;
