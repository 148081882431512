import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { useAppConfig } from '../../../hooks';
import type { LandingInputLayoutAProps } from './variant-a.types';

export function LandingInputLayoutA({
  header,
  banner,
  title,
  subTitle,
  list,
  rightSide,
}: LandingInputLayoutAProps) {
  const { t } = useTranslation('landing');
  const {
    public: { cloudFront },
  } = useAppConfig();

  return (
    <Stack
      style={{
        backgroundColor: '#f7fcfe',
        backgroundImage: `url(${cloudFront.url}/top-bg.webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box flex="0 1 auto">{header}</Box>
      <Box
        flex="0 1 auto"
        display="flex"
        alignItems="stretch"
        flexDirection="row"
      >
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Grid
            container
            px={(theme) => ({
              xs: theme.typography.pxToRem(10),
              md: theme.typography.pxToRem(30),
              lg: '5.3vw',
            })}
            pt="2.4vh" // 65px/2676px
            pb="3vh"
          >
            <Box
              textAlign="center"
              display={{
                xs: 'flex',
                lg: 'none',
              }}
              width="100%"
              mb={{
                xs: 4,
                md: 6,
              }}
              flexDirection="column"
              alignItems="center"
            >
              {title}
              {subTitle}
            </Box>
            <Grid item xs={12} md={6} lg={8}>
              <Stack>
                <Stack
                  display={{
                    xs: 'none',
                    lg: 'block',
                  }}
                  spacing={4}
                >
                  {title}
                  {subTitle}
                </Stack>
                <Box
                  display={{
                    xs: 'none',
                    md: 'block',
                  }}
                >
                  {list}
                </Box>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              mt={{
                xs: '2.4vh',
                md: 0,
              }}
            >
              {rightSide}
            </Grid>
            <Box>
              <Typography pt={4} px={3} component="p" variant="disclaimer">
                {t('emailTermsAndConditions')}
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Box flex="0 1 auto">{banner}</Box>
    </Stack>
  );
}

export default LandingInputLayoutA;
