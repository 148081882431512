import { useTrack } from '@hc-frontend/core-third-party-absmartly';
import type { IRxCard } from '@hc-frontend/shells-rx-cards-entities';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import type { Theme } from '@mui/material/styles';
import type { TextFieldProps } from '@mui/material/TextField';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { CloudImage } from '../../../atoms';
import {
  useSubmitCard,
  useTrackEmailCTA,
  useTrackTextCTA,
} from '../../../hooks';
import { EmailInput } from '../../../molecules/email-input';
import { PhoneInput } from '../../../molecules/phone-input';
import { ReCaptchaProvider } from '../../../molecules/recaptcha-provider';
import type { DiscountCardFormInputType } from './variant-a.types';

function DiscountCardFormError({ error }: Record<'error', string>) {
  const [open, setOpen] = useState(false);

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setOpen(!!error);
  }, [error]);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error">
        {error}
      </Alert>
    </Snackbar>
  );
}
function DiscountCardFormBase() {
  const { t } = useTranslation(['landing', 'form', 'errors']);

  const trackTextCTA = useTrackTextCTA();
  const trackEmailCTA = useTrackEmailCTA();
  const { control, handleSubmit, reset, formState } = useForm<
    Pick<IRxCard, 'email' | 'phoneNumber'>
  >({
    mode: 'all',
    defaultValues: {
      email: '',
      phoneNumber: '',
    },
  });

  const [execute, resetSubmit, { fail, errors, loading, ready }] =
    useSubmitCard();
  const [selectedButton, setSelectedButton] =
    useState<DiscountCardFormInputType>('email');

  const trackAbandoned = useTrack({
    experimentName: 'RX_CARDS_LP',
    goal: 'rx_cards_lp_abandoned',
  });

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'hidden') trackAbandoned();
  }, [trackAbandoned]);

  useEffect(() => {
    trackEmailCTA();

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [trackEmailCTA, handleVisibilityChange]);

  const handleSetSelectedButton =
    (newSelectedButton: DiscountCardFormInputType) => () => {
      if (newSelectedButton === 'email') trackEmailCTA();
      else trackTextCTA();

      reset({ phoneNumber: '', email: '' });
      setSelectedButton(newSelectedButton);
    };

  const getButtonColor = (button: DiscountCardFormInputType, theme: Theme) =>
    button === selectedButton
      ? {
          backgroundColor: theme.palette.secondary.main,
          ':hover': {
            backgroundColor: theme.palette.secondary.main,
          },
        }
      : {
          backgroundColor: theme.palette.neutral?.tertiaryGray as string,
          ':hover': {
            backgroundColor: theme.palette.neutral?.tertiaryGray as string,
          },
        };

  const inputProps = useMemo<TextFieldProps>(
    () => ({
      InputProps: {
        sx: (theme) => ({
          height: theme.typography.pxToRem(44),
        }),
      },
    }),
    [],
  );

  useEffect(() => {
    if (formState.isValidating && !ready) {
      resetSubmit();
    }
  }, [formState, ready, resetSubmit]);

  return (
    <Stack
      spacing={{
        xs: 3,
        lg: 4,
      }}
    >
      <Container
        sx={{
          position: 'block',
        }}
      >
        <CloudImage
          assetName="discount-card.webp"
          layout="responsive"
          height={266}
          width={423}
          priority
          alt="Healthcare.com Rx savings card image"
        />
      </Container>

      <Grid container alignItems="center" justifyContent="space-around" px={3}>
        <Grid item xs={6} pr={1}>
          <Button
            fullWidth
            onClick={handleSetSelectedButton('email')}
            data-testid="email-cta"
            sx={(theme) => ({
              ...getButtonColor('email', theme),
            })}
          >
            {t('email')}
          </Button>
        </Grid>
        <Grid item xs={6} pl={1}>
          <Button
            onClick={handleSetSelectedButton('phoneNumber')}
            fullWidth
            data-testid="phone-number-cta"
            sx={(theme) => ({
              ...getButtonColor('phoneNumber', theme),
            })}
          >
            {t('text')}
          </Button>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(execute)} data-testid="main-form">
        <Box display="flex" flexDirection="row" px={3}>
          {selectedButton === 'email' && (
            <EmailInput
              {...inputProps}
              formControl={control}
              fieldName="email"
            />
          )}
          {selectedButton === 'phoneNumber' && (
            <PhoneInput
              textInputProps={inputProps}
              formControl={control}
              fieldName="phoneNumber"
            />
          )}
          <LoadingButton
            loading={loading}
            sx={(theme) => ({
              py: 3,
              px: 6,
              height: theme.typography.pxToRem(44),
              whiteSpace: 'nowrap',
              ml: 2,
            })}
            color="secondary"
            type="submit"
            variant="contained"
            data-testid="send-card-cta"
            aria-label="Click to send card to your email or phone number as text message"
          >
            {t('sendCard')}
          </LoadingButton>
        </Box>
      </form>

      {fail && (
        <DiscountCardFormError
          error={t(errors.map((e) => e.message).join(), {
            ns: 'errors',
          })}
        />
      )}
    </Stack>
  );
}

export function DiscountCardForm() {
  return (
    <ReCaptchaProvider container="send-card-captcha">
      <DiscountCardFormBase />
    </ReCaptchaProvider>
  );
}

export default DiscountCardForm;
