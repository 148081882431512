import { buildEmail } from '@hc-frontend/shells-rx-cards-business';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';

import { useValidateEntity } from '../../hooks';
import type { EmailInputProps } from './email-input.types';

export function EmailInput<T extends Record<string, string>>({
  fieldName,
  formControl,
  ...inputProps
}: EmailInputProps<T>) {
  const validate = useValidateEntity(buildEmail);

  return (
    <Controller<T>
      rules={{
        validate,
      }}
      name={fieldName}
      control={formControl}
      render={({ field, fieldState }) => (
        <FormControl fullWidth error={fieldState.invalid}>
          <TextField
            {...inputProps}
            {...field}
            id={fieldName}
            fullWidth
            placeholder="you@email.com"
            data-testid={`${fieldName}-input`}
            title={fieldName}
            inputProps={{
              'aria-label': fieldName,
            }}
          />
          <FormHelperText error>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
